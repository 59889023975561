.AuditTrailReportModal {
  .ant-modal-content {
    width: fit-content;
    margin-left: -14rem;
  }

  .AuditTrailDialog {
    .SiteSelector {
      .ant-select {
        position: absolute;
        width: 20em;
      }
      .ant-radio-group {
        position: relative;
        margin-top: 50px;

        .ant-radio-wrapper {
          display: list-item;
        }
      }
    }

    .SubjectsSelector {
      .select-all {
        display: flex;
        margin-bottom: 1em;
      }

      .ant-select-multiple {
        width: 50%;
        margin-bottom: 1em;
      }
    }

    .EventTypesSelector {
      .select-all {
        margin-bottom: 1em;
      }

      .event-type-option-desc {
        font-weight: 400;
      }
    }

    .DateRangeSelector {
      .select-all {
        display: flex;
        margin-bottom: 1em;
      }
    }
  }
}
