.VisitComponentDataQueryFlagOverlay,
.MissingFieldsStatusIndicatorOverlay
{
  .ant-popover-inner-content {
    text-align: center;
    .DataItemQueryStatus {
      cursor: pointer;
    }
  }
}

.MissingFieldsStatusIndicator {
  // relative positioning is for consistency with classic procedure pill icons
  position: relative;
  top: 1px;
  color: #550055; // matches old 'incomplete' icon

  &.complete {
    color: #57bbed; // matches old 'complete' icon
  }

}
