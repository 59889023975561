// atrium:
$color-base-white: #fff;

// figma design colors
$color-light-blue: #88c4e9;

// protocol first misc colors
$color-history-field-border: #dddddd;
$color-history-field-selected-background: #f7f7f7;
$color-history-field-changed-value-background: #ffffcc;
$color-error-text: rgba(255, 77, 79, 0.85);

// bootstrap
$color-bootstrap-warning-fg: #8a6d3b;
$color-bootstrap-warning-bg: #fcf8e3;

//.procedure-data-entry .HistoryField,
//.GuidanceSection .HistoryField
.HistoryField {

  border: solid 1px $color-history-field-border;
  background-color: $color-base-white;
  padding: 10px 15px;
  margin: 3px;
  display: flex;
  flex-direction: column;

  &.selected-field-for-query-detail {
    background-color: $color-history-field-selected-background;
  }

  &.field-value-is-changed {
    background-color: $color-history-field-changed-value-background;
  }

  &.is-edited-after-review {
    background-color: $color-bootstrap-warning-bg;
    color: $color-bootstrap-warning-fg;
  }

  .field-header {

    width: 100%;
    display: flex;
    flex-flow: row;

    .title-bar {

      flex: 0 1 auto;

      .icon-button{
        margin: 0;
        margin-left: 5px;
        padding: 0;
      }

      svg {
        margin: 0;
        margin-left: 2px;
        margin-right: 2px;
        padding: 0;
      }

      .icon-button, svg {
        color: $color-light-blue;
      }
    }

    .menu-bar {
      flex: 1 1 auto;
      align-self: center;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

  } // .field-header

  .field-content {
    margin-bottom: 0.5rem;

    .ant-radio-group {
      display: grid;
    }



    .IntegerFieldForm,
    .FloatFieldForm,
    .StringFieldForm {
      width: 30ex;
    }
    .StringFieldForm.error::after {
      color: $color-error-text;
      right: 0;
    }


    .ant-picker-input {

      min-width: 26ex; // wide enough to show '08/10/2022 11:45:25 am'

    }

    label.ant-radio-wrapper,
    label.ant-checkbox-wrapper,
    {
      font-weight: normal; // counteract bootstrap's bold
    }

    .clear-button-box {
      margin-top: 1rem;
      .clear-button {

        // follows original bootstrap .btn-danger styling

        color: white;
        background-image: linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%);
        background-repeat: repeat-x;
        border-color: #b92c28;
        border-radius: 4px;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
      }
    }

    .DatePartialFieldForm,
    .DatetimePartialFieldForm
    {
      .is-unknown-control {
        border: solid 1px rgb(217, 217, 217); // to match select, input
        background-color: rgb(245, 245, 245); // to match
                                              // disabled select, input
        height: 30px; // matches number handler
        padding-top: 3px; // compensate extra height to center text
        padding-left: 8px; // matches label span's padding-left

      }

      .month-control {
        width: 12rem; // accommodate widest month
      }

      .hour-control {
        width: 12rem; // show whole placeholder

        input {
          // was getting a spurious control image
          background-image: none !important;

        }
      }

      // form item adjustments

      .ant-form-item {
        margin: inherit;
      }

      .ant-form-item-control-input {
        align-items: normal;
      }

      .ant-form-item-control > div:not(.ant-form-item-control-input) {
        position: absolute;
        top: 40px;
        //background-color: lightgreen;
        display: none !important;
      }


    }

    h4 {
      // counteract title styling for
      // read-only PickMany
      color: inherit;
      font-size: inherit;
    }
  } // .field-content

  &.PickOneLongListField {
    .field-content {
      .ant-select {
        min-width: 200px;
      }
    }

    .pick-one-long-list-field-container {
      display: flex;
      align-items: flex-start;
    }
  }

  &.PickManyField {
    .field-content {
      .checkbox-group {
        display: grid;
      }
    }
  }

  .pick-many-item,
  .pick-one-item
  {
    margin-bottom: 4px;
  }

  &.FloatField,
  &.IntegerField,
  &.DatePartialField,
  &.DatetimePartialField {
    .Alert {

      margin-bottom: 10px;

      ul {
        margin: 0;
      }

    }
  }

} // .HistoryField

.history-table-title {
  color: rgba(0, 0, 0, 0.85);
  font-family: "Titillium Web";
  font-size: 18px;
  margin-top: 1em;
}