@use 'scss/common';

.DataItemQueryStatus {

  .ant-badge {
    & > sup {
      font-size: 12px;
      color: white;
    }


    &.open > sup {
      background-color: common.$data-item-query-status-color-open;

    }

    &.answered > sup {
      background-color: common.$data-item-query-status-color-answered;
    }

    &.closed > sup {
      background-color: common.$data-item-query-status-color-closed;
    }
  }

}
