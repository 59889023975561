$color-base-gray: #777777;

//  button colors
$color-base-red: #f25929; // p1 split button text, border
$color-base-green: #419641; // p1 state button: final 
$color-base-amber: #eb9316; // p1 state button: in-between


.ProcedureSubjectLogView {

  .ProcedureSubjectLogTable {

    .procedure-name {
      font-weight: bold;
    }

    .Table {

      margin-top: 2ex;
      margin-bottom: 1ex;

      .ant-table-row {
        &.crossout {
          text-decoration: line-through;
        }
      }

      .ant-table-cell {
        div {
          color: $color-base-gray
        }
      }

    } // .Table


  } // .ProcedureSubjectLogTable
} // .ProcedureSubjectLogView
