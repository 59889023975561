$color-incomplete: #eb9316;
$color-incomplete-border: rgb(227, 141, 19);
$color-incomplete-badge-text: rgb(240, 173, 78);
$color-complete: #419641;
$color-complete-border: rgb(62, 143, 62);
$color-complete-badge-text: #5cb85c;

.FieldCountDisplay[disabled],
.FieldCountDisplay[disabled]:hover {

  color: white !important;
  height: 35px;
  background-color: $color-complete !important;
  border-color: $color-complete-border !important;
  border-width: 1px;
  border-radius: 4px;
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0;
  margin-top: 1px;
  margin-bottom: 1px;
  vertical-align: middle;

  // Prevent 'disallowed' cursor because the button is disabled
  cursor: default;

  .FieldCountDisplayBadge {
    margin-left: 7px;
    sup {
      background-color: white;
      font-weight: bold;
      color: $color-complete-badge-text;
      border-radius: 10px;
      height: 18px;
      padding: 0px 8px;
    }
  }

  &.incomplete {
    background-color: $color-incomplete !important;
    border-color: $color-incomplete-border !important;
    .FieldCountDisplayBadge {
      sup {
        color: $color-incomplete-badge-text;
      }
    }
  }

}
