.site-pi-sig-content {

  .left-aligned-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 50px;
    padding: 8px;
  }

  .status-column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 50px;
    padding: 8px;
  }

  .status-icon {
    color: #52c41a !important;
    margin-left: 8px !important;
  }

  .details-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 50px;
    padding: 8px;
  }

  .details-text {
    margin: 0;
  }

  .popover-content {
    max-height: 150px;
    overflow-y: auto;
  }

  .popover-button {
    margin-left: 8px;
  }

  .spinner-container {
    text-align: center;
    margin-top: 20px;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .filter-dropdown {
    padding: 8px;
  }

  .filter-dropdown .filter-options {
    margin-bottom: 8px;
  }
  .site-pi-sig-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }

  .checkbox-label {
    margin-left: 2px;
  }
  .custom-glyphicon-certificate {
    color: #28a745;
  }
}