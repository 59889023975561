@use 'scss/query-display';
$color-text-black-secondary: rgba(0,0,0,0.54);

$color-base-icon-unfiltered-column: #bfbfbf; // match antd header controls
$color-base-icon-filtered-column: #1890ff; // match antd header controls

.QueryTable {
  padding-bottom: 2rem;

  thead {
    position: sticky;
    top: 50px;
    z-index: 1;
  }

  .procedure-detail,
  .crossed-out-log-row-notice {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: $color-text-black-secondary;
  }

  .ColumnFilterIcon {

     color: $color-base-icon-unfiltered-column;

     &.filtered {
       color: $color-base-icon-filtered-column;
     }
  }

} // .QueryTable

// out of main page
.SubjectContextFilterControl,
.SiteSelector {
  width: 100%;
}

.SiteNumberSiteSerialNumberFilter {
  width: 200px;
}

.QueryTableQueryItemDetail {
  @include query-display.query-display;
} // .QueryTableQueryItemDetail
