// atrium colors
$color-base-background-gray: #f4f4f7;
$color-base-gray-light: #e0e0e0;
$color-base-white: #fff;
#ProtocolNavigation {

  #ProtocolNavigation-panel-queries {

    background-color: $color-base-white;
      border-left: solid 1px $color-base-gray-light;
      border-right: solid 1px $color-base-gray-light;
      border-bottom: solid 1px $color-base-gray-light;
      padding: 5px;
      margin-bottom: 5px;
  }

}
