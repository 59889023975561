.signature-details-wrapper {
    #pisig-status {
      span {
        color: black;
      }
    }
  
    #pisig-signed-data {
      .success-icon {
        font-size: 20px;
        color: #28a745;
      }
  
      .success-text {
        color: #586069;
      }
  
      .error-icon {
        font-size: 20px;
        color: #dc3545;
      }
  
      .error-text {
        color: #dc3545;
      }
    }
    .custom-glyphicon-certificate {
      color: #28a745;
    }
  }