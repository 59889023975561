$color-base-gray-light: #E0E0E0;
$color-base-gray: #9E9E9E;
$color-base-gray-dark: #5e5e5e;
$color-base-black: #000;
$color-base-red: #B70016;
$color-base-green: #009E57;
$color-answered-queries-text: #F3A831;

.field-actions-button-container-anchor {
  width: 0;
  position: relative;
  right: 25px;

  &.field-query-button-split {
    padding-top: 10px;
    right: 0px !important;
    width: 100% !important;
  }

  .field-query-button-container {
    white-space: nowrap;

    .field-query-buttons {
      display: flex;
      border-color: $color-base-gray-light;
      border-style: solid;
      border-radius: 50px;
      border-width: 1px;

      padding: 7px;
      padding-top: 5px;
      padding-bottom: 5px;

      background: white;

      cursor: default;

      .field-review-button-container {
        display: flex;

        .field-review-button-container.field-query-button.reviewable-field-button.reviewer {
          cursor: pointer;
        }
      }

      .ant-divider-vertical {
        border-left: 1px solid $color-base-gray-light;
        height: 20px;
      }

      .field-query-button {
        border-color: $color-base-gray-light;
        border-style: solid;
        border-radius: 500px;
        border-width: 1px;

        padding-left: 8px;
        padding-right: 8px;

        margin-left: 3px;
        margin-right: 3px;
        font-size: 12px;
      }

      .open-field-query-button {
        color: $color-base-red;
      }

      .reviewable-field-button {
        color: $color-base-gray-dark
      }

      .answered-field-query-button {
        color: $color-answered-queries-text;
      }

      .closed-field-query-button {
        color: $color-base-green;
      }

      .new-field-query-button {
        color: $color-base-gray-dark;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        align-items: center;

        cursor: pointer;

        .new-field-query-text {
          padding-left: 6px;
          font-size: 13px;
        }

        .new-field-query-icon {
          margin-top: 2px;
          color: $color-base-gray;
          margin-bottom: 2px;
          height: 17px;
          width: 17px;

          &.new-field-query-icon-active {
            color: $color-base-gray-dark;
          }

          &.new-field-query-icon-inactive {
              color: $color-base-gray;
          }
        }
      }
    }
  }
}
