$text-black-secondary: rgba(0,0,0,0.54);
// manipulate some classic styling
#dashboard {
  #protocol_container {
    #protocol_list {
      #dashboard-list {
        #protocol-site-information {

          .row {
            margin: 0;

            .col-md-10 {

              padding: 0;
            //background-color: orange;

              #protocol-short-title {
                color: $text-black-secondary;
                font-family: 'Titillium Web';
                font-style: normal;
                font-weight: 400;
                font-size: 23px;
                line-height: 24px;
              }

            } // col-md-10
            .col-md-2 {
              //background-color: yellow;
              padding: 0;
              #dashboard-actions{
                float: right;

                #admin-function-button {
                  .caret {
                    margin-left: 30px;
                  }
                }

                .dropdown-menu {
                  left: -62px;
                  top: calc(100% - 2px);
                }

              }
            } // .col-md-2

          }
        }
        #dashboard-content {
        } // #dashboard-content
      }
    }

  }
}

.ProtocolLevelQueryList {


  .ant-page-header {

    padding: 0;
    padding-top: 0px;

    .ant-page-header-heading-left {
      flex: 1 1 auto;

      .ant-page-header-heading-title {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 37px;
        overflow: visible;
      }

    } // .ant-page-heading-left


    .ant-page-header-heading-extra {

      flex: 1 1 auto;
      width: 100%;
      margin-bottom: 4px;
      margin-top: 4px;
      height: 37px;

      .ant-space {

        width: 100%;
        height: 37px;

        .ant-space-item {
          width: 100%;

          .toolbars {

            display: flex;
            flex-direction: row;
            width: 100%;

            .query-download-button {
              margin-left: 8px;
              flex: 0 1 auto;
            }

            .toolbar {
              flex: 1 1 auto;

              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;

              padding-right: 8px;

              .ant-pagination {
                padding-left: 10px;
              }

            } // .toolbar
          } // .toolbars

        } // .ant-space-item
      } // .ant-space


    } //.ant-page-header-heading-extra
  } // .ant-page-header

} // ProtocolLevelQueryList
