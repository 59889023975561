// somehow the queryKey area is white-on-white. Ugh!
div#ReactQueryDevtoolsPanel.ReactQueryDevtoolsPanel {
  //color: black !important;
}

// Styles for Data Entry History tables from classic code
.item-history {
  border: 0px;
  padding: 0px;
  font-size: 14px;
  
  & > div.table-responsive > .table > tbody > tr > td {
    padding: 16px;
    border-top: 0px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  & > div.table-responsive > .table > tbody > tr:first-child {
    font-weight: 500;
    background: #fafafa;
  }
}

tr[id^="grid-row-"] {
  background: white;
  border: 1px solid #dddddd;
}
