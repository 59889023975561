.edit-check-notifications {
	position: fixed;
	bottom: 80px;
	right: 20px;
	width: 300px;
	border: 1px solid #00000033;
	border-radius: 4px;
	background-color: white;
	z-index: 1000;
	overflow: auto;
	max-height: calc(100% - 40px);
	margin: 15px;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 3px;
		background: #B70016;
	}

	h3 {
		font-family: 'Titillium Web', sans-serif;
		font-size: 15px;
		font-weight: 400;
		line-height: 22.5px;
		padding-top: 3px;
		text-align: left;
		margin: 20px 13px 15px 13px;
	}
}

.edit-check-notification {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-top: 1px solid #ccc;
	padding: 13px;
	background: #FFF;
	width: 100%;

	.edit-check-notification-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-right: 10px;
	}

	.edit-check-metadata {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		margin: 4px 0;
	}

	.edit-check-category {
		font-family: Arial, sans-serif;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		text-align: left;
		flex: 1;
		flex-basis: 15%;
		color: #0000008A;
		min-width: 10%;
		margin-right: 13px;
	}

	.edit-check-value {
		font-family: Arial, sans-serif;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		text-align: left;
		flex: 2;
		margin-left: 5px;
		flex-basis: 80%;
	}

	.close-edit-check-notification-button {
		flex-shrink: 0;
		cursor: pointer;
	}
}
