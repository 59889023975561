#ApiScopeEditor,
#ApiAssociateScopeEditor
{

  margin: 1ex;
  margin-bottom: 3ex;

  .scope-guidance {
    font-style: italic;
    padding-left: 30px;
    margin-top: 1ex;
    margin-bottom: 1ex;
  }

}

.ApiScopeSelectionForm {

  .ant-select {
    max-width: 50em;
  }

  label {
    font-weight: normal; // override some bootstrap styling
  }

  .api-scope-category {

    padding-left: 1ex;
    margin-bottom: 1ex;

    .ant-form-item {
      padding-left: 1em;
      margin-bottom: 0;
    }

  } // .api-scope-category

  .buttons {
    padding-left: 1ex;
    button {
      margin-right: 5px;
    }
  }

} // .ApiScopeSelectionForm

.ant-select-dropdown {
  button.select-dropdown-action {
    margin: 5px;
  }
}
