@use 'scss/common';

$color-base-background-gray: #f4f4f7;
$color-base-gray-light: #e0e0e0;
$color-text-black-secondary: rgba(0,0,0,0.54);
$color-base-white: #ffffff; 

$color-bootstrap-button-primary: #f25929;
$color-bootstrap-button-primary-hover: #d94010;
$color-bootstrap-button-disabled-hover: #990000;

.Query {

  background-color: $color-base-background-gray !important;

  // non-Collapse version is a Card
  & > .ant-card-head
  {
    border: solid 1px $color-base-gray-light !important;
    padding: 0;
    font-size: 14px;
    line-height: 22px;

    .ant-card-head-wrapper {
      background-color: $color-base-background-gray;
      .ant-card-head-title {
        padding: 12px 16px; // consistency with Collapse component styling
      }
    }

  }

  // non-Collapse version is a Card
  & > .ant-card-body {
    padding: 16px; // consistency with Collapse component styling
    padding: 0;
    background-color: white;
    border: solid 1px $color-base-gray-light !important;

  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .DmRoleCloseOrReplyForm,
  .SiteRoleAnswerForm,
  .SiteRoleReplyForm {

    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 0;

    .text {
      margin-bottom: 16px;
    }

    .control-bar {

      display:flex;
      justify-content: space-between;
      button.closeButton {
        width: 48%;
      }
      button.replyButton {
        width: 48%;
      }
    }
  }

  .QueryHeader {

    font-weight: bold;

    .status-indicator {
      padding-left: 30px;
      &.open {
        .ant-badge-status-dot {
          background-color:
            common.$data-item-query-status-color-open !important;
        }
      }

      &.answered {
        .ant-badge-status-dot {
          background-color:
            common.$data-item-query-status-color-answered !important;
        }
      }

      &.closed {
        .ant-badge-status-dot {
          background-color:
            common.$data-item-query-status-color-closed !important;
        }
      }

    }

  } // .QueryHeader

  .message-list {

    .message {

      padding: 20px 16px;


      border-bottom: solid 1px $color-base-gray-light;

      .author-details {
        .author-name {
          font-weight: bold;
        }
      } // .author-details
      .date {
        font-style: italic;
        font-size: small;
        color: $color-text-black-secondary;
      }
      .message-content {
        margin-top: 1ex;
      }
    } // .message

    .message:last-child {
      border-bottom: 0;
    }
  }

} // Query
