@mixin query-display {
  .ant-drawer-content-wrapper {

    padding-top: 50px; // banner height

    .ant-drawer-header {

      flex-direction: column; // title over extra

      .ant-drawer-header-title {

        width: 100%;
        // move X to right
        justify-content: right;
        flex-direction: row-reverse;

        .ant-drawer-title {

          // ellipsis
          white-space: nowrap;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;

          .EdcNewQueryFormTitle {
            // title font
            font-family: 'Titillium Web';
            font-weight: 400;
            font-size: 17px;
            line-height: 150%;
          }
        } // .ant-drawer-title

      } // .EdcNewQueryFormTitle

      .ant-drawer-extra {
        width: 100%;
        button {
          padding-left: 0;
          border-left: 0;
        }
        // navigation link
        .EdcNewQueryViewQueriesButton {
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: rgba(0, 0, 0, 0.54);

          span {
            text-decoration: underline;
          }
          
        }
      } // .ant-drawer-extra


    }

    .ant-drawer-body {

      .QueryList {

        .Query {
          margin-bottom: 2px;
        }

        .Query:last-child {
          margin-bottom: 0;
        }

      } // .QueryList
      
      .NewQueryDialog {
        .EdcNewQueryFormButton {

          box-sizing: border-box;
          width: 163px;
          height: 38px;

          background: #FFFFFF;
          border: 1px solid #E0623A;
          border-radius: 2px;
        }
      } //.NewQueryDialog 

    } // .ant-drawer-body
  } // .ant-drawer-content-wrapper
} // @mixin query-display
