@use 'scss/query-display';

// atrium
$color-base-white: #fff;

// classic, probably
$color-base-red: #f25929;
$color-base-red-hover: #d94010;
$color-base-classic-black: rgb(0, 0, 0);
$color-base-classic-faded-black: rgba(0, 0, 0, 0.87);

// classic
$color-base-classic-light-gray: #ddd;
$color-base-very-light-gray :#f7f7f7;

// bootstrap
$color-bootstrap-warning-fg: #8a6d3b;
$color-bootstrap-warning-bg: #fcf8e3;

// classic procedure view area
#procedure-tab-content {


  .EdcPlusAnsweredProcedureLevelQueryBadge{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 3px;
  
    color: $color-base-classic-faded-black;
    background: rgba(243, 168, 49, 0.1);
  
    & > sup {
      background-color: rgba(243, 168, 49, 0.1);
      color: black;
      border: 1px solid #F3A831;
    }
  
  }
  
  .ProcedureQueryDisplayControlButton {
  
    color: $color-base-classic-black;
    
    border-color: black;
    border-radius: 4px;
    background-color: white;
    &:hover {
      color: $color-base-red-hover;
      border-color: $color-base-red-hover;
    }
  }

  .EdcPlusProcedureLevelQueryBadge{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 3px;

    color: $color-base-classic-faded-black;
  
    & > sup {
      background-color: rgba(227, 49, 34, 0.1);
      color: black;
      border: 1px solid #E33122;
    }  
  }

  // field control

  div.react-field-control-container { // not grid table
      display: flex;
      flex-flow: row;

    & > .react-field-control-classic-content {
      flex: 1 1 auto;
    }
    & > .react-field-control-react-content {
      flex: 0 1 auto;
    }
  }

  tr.react-field-control-container {

    & > td.react-field-control-react-content { // grid only rule

      background-color: white;
      max-width: 290px !important;
      width: 290px !important;
      overflow: visible;   

      .react-field-control-flex-container {
        display: flex;
        justify-content: right;
        direction: ltr;
      }

      div {
        text-align: start;

        .EdcPlusAnsweredButton,
        .EdcPlusOpenButton
        {
          margin-bottom: 3px;
          &:last-child {
            margin-bottom: 0;
          }

          & > span {
            direction: ltr;
          }
        }
      }

    }

  }

  tr.react-field-control-container,
  div.react-field-control-container {

    background-color: $color-base-white;

    border: solid 1px $color-base-classic-light-gray;

    margin: 3px;

    &.selected-field-for-query-detail {
      background-color: $color-base-very-light-gray;
    }

    // don't overwrite classic list-group-item-warning styling
    // for fields whose value is changed (esp for monitors
    // where the value has changed since the review status became
    // 'reviewed' or 'review comment')
    &.list-group-item-warning {
      background-color: $color-bootstrap-warning-bg;
      color: $color-bootstrap-warning-fg;
    }


    & > .react-field-control-react-content {

      text-align: end; // ensure button is at right

    } // react-field-control-react-content
    
  } // div.react-field-control-container

} // #procedure-tab-content

.VisitProcedureQueriesDrawer,
.VisitProcedureFieldQueriesDrawer {
  @include query-display.query-display;
} // .VisitProcedureQueriesDrawer, .VisitProcedureFieldQueriesDrawer
