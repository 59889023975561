// atrium colors
$color-base-background-gray: #f4f4f7;
$color-base-gray-light: #e0e0e0;
$color-base-white: #fff;

body {

  background-color: $color-base-background-gray;

  div#react-app-root {
    height: 0;
    width: 0;
    padding: 0 !important; // react-query tries to add 162px padding-bottom
    margin: 0;
  }

  #protocol_list, #react-main-content {
    // override bootstrap styling for col-xs-12 and col-md-9
    padding-left: 0;
    padding-right: 0;
  }

  #site-page,
  #subject-page,
  #protocol-site-information
  {
    #site-tabs {
      border-bottom: solid 1px $color-base-gray-light;

      &.nav-tabs > li.active > a,
      &.nav-tabs > li.active > a:hover,
      &.nav-tabs > li.active > a:focus {
        border-top: solid 1px $color-base-gray-light;
        border-left: solid 1px $color-base-gray-light;
        border-right: solid 1px $color-base-gray-light;
      };
    }

    #tab-content,
    #dashboard-content
    {
      background-color: $color-base-white;
      border-left: solid 1px $color-base-gray-light;
      border-right: solid 1px $color-base-gray-light;
      border-bottom: solid 1px $color-base-gray-light;
      padding: 5px;
      margin-bottom: 5px;

    }
  }

  .row {
    margin: 0; // correct some bootstrap margin-left and margin-right -15px
  }

} // body

ul.nav-pills {
  li > a {
    background-color: $color-base-white;
    ;
  }
}
