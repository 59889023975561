$color-base-gray: #777777;

//  button colors
$color-base-red: #f25929; // p1 split button text, border
$color-base-green: #419641; // p1 state button: final 
$color-base-amber: #eb9316; // p1 state button: in-between

// todo code reuse: SubjectLogProcedureItemView.scss, QueryDisplay.scss
// see, eg, EdcPlusProcedureLevelQueryBadge
.ProcedureSubjectLogItemModal {

  width: 925px !important;

  .header {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .tool-bar {
    flex: 0 1 auto;
    padding-left: 15px;


    button.split-right {
      border-left: 0px;
    }

    button.incomplete-state,
    button.incomplete-state:hover,
    button.incomplete-state:hover:active
    {
      background: $color-base-red;
      color: white;
      border: $color-base-red;
    }

    button.in-between-state,
    button.in-between-state:hover,
    button.in-between-state:hover:active
    {
      background: $color-base-amber;
      color: white;
      border: $color-base-amber;
    }

    button.final-state,
    button.final-state:hover,
    button.final-state:hover:active
    {
      background: $color-base-green;
      color: white;
      border: $color-base-green;
    }
  } // .tool-bar


  .EdcPlusProcedureLevelQueryBadge{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 3px;
    color: rgba(0, 0, 0, 0.87);

    & > sup {
      background-color: rgba(227, 49, 34, 0.1);
      color: black;
      border: 1px solid #E33122;
    }
  }

  .EdcPlusAnsweredProcedureLevelQueryBadge{

    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 3px;
    color: rgba(0, 0, 0, 0.87);
    background: transparent;

    & > sup {
      background-color: rgba(243, 168, 49, 0.1);
      color: black;
      border: 1px solid #F3A831;

    }
  }

  button.icon-button {
    margin: 1em
  }

} // .SubjectLogProcedureItemModal
