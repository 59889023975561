// atrium:
$color-base-white: #fff;

// protocol first misc colors
$color-guidance-checked-background: #dff0d8;

.GuidanceSection {

  .isChecked, .isNotChecked {
    padding-top: 3px;
    padding-left: 12px;

    .markdown-wrapper {
      display: inline-flex; // emulates label shrinkwrap behavior
    } // .markdown-wrapper
  }

  .isChecked {
    background-color: $color-guidance-checked-background;
  }

  .isNotChecked {
    background-color: $color-base-white
  }

  p {
    margin-bottom: 0;
  }

} // .GuidanceSection

