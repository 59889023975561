$color-history-icon: #1890ff;

.td-subject-log-audit-trail-display-control {
  padding: 0 !important;
  margin: 0;
  vertical-align: middle !important;
}

.td-subject-log-open-control
{
  display: flex;
  justify-content: center;
  padding-left: 0 !important; // somehow it defaults to 49px
  padding-right: 0 !important;
  margin: 0;
}

.SubjectLogRowAuditTrailDisplayControl {
  margin: 0;
  padding: 0;
  width: 100%;

  .icon-button{
    margin: 0;
    margin-left: 5px;
    padding: 0;
    border: 0 !important;
  }

  svg {
    margin: 0;
    margin-left: 8px;
    margin-right: 2px;
    padding: 0;
    font-weight: bold;
  }

  .icon-button, svg {
    color: $color-history-icon;
  }
}

.SubjectLogRowAuditTrailDisplay {
  width: 60% !important;
}
