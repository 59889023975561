#source-document-display-link {
    font-size: 13px;
    padding-left: 5px;
    padding-top: 20px;
    max-width: fit-content;
    margin-bottom: 10px;
}

.source-document-table-header {
    font-weight: bold;
}

.source-document-divider {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.source-document-modal {
    .ant-modal-body {
        min-height: 200px;
    }
}