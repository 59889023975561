@use 'scss/query-display';

// atrium:
//$color-base-red: #b70016;
//$color-base-light-red: #f2dede;
//$color-base-green: #009e57;
$color-base-gray-dark: #5e5e5e;
$color-base-gray: #9e9e9e;
$color-base-white: #fff;
$color-base-gray-light: #e0e0e0;
$color-base-background-gray: #f4f4f7;

// protocol first button colors
//$color-base-red: #c12e2a; // p1 state button: 'Incomplete'
$color-base-green: #419641; // p1 state button: final 
$color-base-amber: #eb9316; // p1 state button: in-between

$color-base-red: #f25929; // p1 split button text, border
$color-base-red-hover: #d94010; // p1 split button text, border hover

// figma design colors
$color-light-blue: #88c4e9;

// protocol first misc colors
$color-history-field-border: #dddddd; // keep for .Notice

#log-listing {

  span#log-listing-notice {
    p {
      margin: 0;
    }
  }

  #tabletop-toolbar {
    display: flex;
    flex-direction: row;

    &>button,
    &>input {
      flex: 0 1 auto;
      margin-right: 10px;
    }

    &>input {
      border-radius: 4px;
      padding-left: 1rem;

    }

    ul.dropdown-menu {
      width: 18rem;
    }

  }

  #log-rows-table-container {
    margin-top: 1rem;
    table {

      background-color: white;
      border: solid #eee 1px;

      thead {

        tr {

          th {

            color: black;
            text-transform: none;
            font-size: 14px;
            font-weight: 700;
            font-family: 'Open Sans';
            border-bottom: 1px; // override bootstrap's 2px

          }  // th

        } // tr

      } // thead

      tbody {

        tr {
          background-color: white;

          .status-column {
            &>span.status-text {
              display: inline-block;
              min-width: 12rem;
              padding: 3px 5px;
            }
            &>span.icon {
            }
          }

          button {
            border-style: solid;
            background-color: transparent;
            border-width: 1px;
            border-radius: 4px;

            color: $color-base-red;
            border-color: $color-base-red;
          }

          button:hover {
            color: $color-base-red-hover;
            border-color: $color-base-red-hover;
          }

        }

      } // tbody

    } // table

  }

} // #log-listing

.SubjectLogProcedureItemView {
  width: 75%; // to match #protocol_list
  margin-bottom: 5px;
  .SubjectLogProcedureItemEditForm {
    margin: 20px 10px 10px;
    padding: 20px 10px 10px;
  }
}

.SimplifiedSubjectLogProcedureItemView {
  border-top: solid 1px $color-base-gray-light;
  .SubjectLogProcedureItemEditForm {
    padding: 20px;
  }
}

.SubjectLogProcedureItemView,
.SimplifiedSubjectLogProcedureItemView {

  background-color: $color-base-white;

  display: flex;
  flex-direction: row;

  // border and margin to match #tab-content from index.scss
  border-left: solid 1px $color-base-gray-light;
  border-right: solid 1px $color-base-gray-light;
  border-bottom: solid 1px $color-base-gray-light;

  .ant-spin-nested-loading {
    width: 100%; // otherwise its definition bleeds
  }


  h4, .h4 {
    font-family: "Titillium Web";
    color: $color-light-blue;
    font-size: 18px;
    margin-top: 1em;
  }

  .title h4 {
    margin-top: 0;
  }

  .SubjectLogProcedureItemEditForm {

    background-color: $color-base-background-gray;

    .tool-bar {

      button {
        color: $color-base-red;
        border-color: $color-base-red;
        &:hover {
          color: $color-base-red-hover;
          border-color: $color-base-red-hover;
        }
      }

    } // .tool-bar

    button.icon-button {

      border-width: 0px;
      background-color: transparent;
      padding: 0.5rem;
      margin: 0.5rem;

    }

    button.guidance-icon-button {
      color: $color-light-blue;
      border-width: 0px;
      background-color: transparent;
      padding: 0.5rem;
      margin: 0.5rem;
      position: relative;

    }

    a.guidance-icon-button {
      color: $color-light-blue;
      border-width: 0px;
      background-color: transparent;
      padding: 0.5rem;
      margin: 0.5rem;
      position: relative;
      top: -10px;

    }

    .ProcedureLevelQueryButton {
      font-family: 'Arial';
      font-style: normal;
      font-size: 13px;
      background: #FFFFFF;
      color: rgba(0, 0, 0, 0.87);
      border: 1px solid #9E9E9E;
      text-align: left;

    }


    .EdcPlusProcedureLevelQueryBadge{
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      margin-left: 3px;
      color: rgba(0, 0, 0, 0.87);

      & > sup {
        background-color: rgba(227, 49, 34, 0.1);
        color: black;
        border: 1px solid #E33122;
      }

    }

    .EdcPlusAnsweredProcedureLevelQueryBadge{

      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      margin-left: 3px;
      color: rgba(0, 0, 0, 0.87);
      background: transparent;

      & > sup {
        background-color: rgba(243, 168, 49, 0.1);
        color: black;
        border: 1px solid #F3A831;

      }

    }

    .ProcedureQueryDisplayControlButton {

      color: black;
      border-color: black;
      border-radius: 4px;
      background-color: white;
      &:hover {
        color: $color-base-red-hover;
        border-color: $color-base-red-hover;
      }
    }

    .header {

      padding-bottom: 1rem;
      display: flex;
      flex-direction: row;

      button.return-control,
      button.return-control:hover {
        border: 0;
        color: $color-base-gray;
        flex: 0 1 auto;
        margin-right: 1rem;
      }

      button.return-control:hover {
        color: $color-base-gray-dark;
      }

      .title {
        flex: 1 1 auto;

        h4 {
          line-height: 32px; // to match buttons
          color: $color-light-blue;
        }
      }

      .tool-bar {
        flex: 0 1 auto;


        button.split-right {
          border-left: 0px;
        }

        button.incomplete-state,
        button.incomplete-state:hover,
        button.incomplete-state:hover:active
        {
          background: $color-base-red;
          color: white;
          border: $color-base-red;
        }

        button.in-between-state,
        button.in-between-state:hover,
        button.in-between-state:hover:active
        {
          background: $color-base-amber;
          color: white;
          border: $color-base-amber;
        }

        button.final-state,
        button.final-state:hover,
        button.final-state:hover:active
        {
          background: $color-base-green;
          color: white;
          border: $color-base-green;
        }

      } // .tool-bar
    } // .header

    .Notice {
      background-color: $color-base-white;
      border: solid 1px $color-history-field-border;
      padding: 10px 15px;
      margin: 3px;
      ul > li > p {
        margin-bottom: 0; // override 1em between each li item
      }
    }

    .procedure {

      padding-top: 1rem;

      .procedure-data-entry {

        h4 {
          color: $color-light-blue;
        }

      } // .procedure-data-entry

      .procedure-evidence {

        margin-top: 2rem;

        .empty-evidence-notice,
        .thumbnails {
          margin-top: 1rem;
        }

        .evidence-document-list {
          .evidence-document {

            display: inline-block;
            margin-top: 1rem;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
            border: solid black 1px;

            .evidence-image-gallery {
              margin-left: 1rem;
              margin-right: 1rem;
            }

            .toolbar {
              margin-top: 1.5rem;
            }

            .evidence-media {

              display: flex;
              flex-direction: column;
              background-color: #ebebed;
              border: solid 1px #778;
              padding: 1rem;

              .evidence-media-image {
                border: solid #aaa 1px;
              }
              .evidence-media-checkbox {
              }

            }
          }
        }

      } // .procedure-evidence


      margin-bottom: 2rem;

    } // .procedure

    .ProtocolVersionLabel {
      margin-top: 2rem;
      border-top: solid 1px black;
      font-size: small;

    }


  } // SubjectLogProcedureItemEditForm

  .SubjectLogQueries {

    flex: 0 1 auto;
    margin: 0px auto;

    h1 {
      font-weight: bold;
      font-size: 14px;
    }

  }

} // SubjectLogProcedureItemView

.ant-modal-root {

  .ReviewDialogContent {

    button[type='submit'] {
      display: none;
    }

  }

  .ReviewHistory {

    .ant-modal-body {
      height: 50vh;
      overflow: auto;
    }

    .ant-modal-footer {

      button.ok-button {
        display: none;
      }

    }

  }

  .ReasonForChangeDialog {

    .reason-selector {
      margin-top: 1rem;
      width: 100%;
    }

    .other-reason-input {
      margin-top: 1rem;
    }

    .reason-dialog-alert {
      margin-bottom: 1rem;
    }
  }


}

.SubjectLogProcedureFieldActionsDrawer {

  @include query-display.query-display;

} // .SubjectLogProcedureFieldActionsDrawer

#tab-content .hidden {
  display: none;
}
