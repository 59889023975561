.ClinicalListingModal {
  height: calc(100vh - 300px);
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-modal-body {
      overflow: auto !important;
      height: 100%;

      .ClinicalListingDialog {
        height: 100%;
        width: 100%;

        .ant-spin-container {
          height: 100%;
          border-bottom: none;

          .SitesSelector,
          .SubjectsSelector,
          .SubjectLogsSelector {

            border-bottom: none;

            .advice {
              font-style: italic;
            }
            .select-all {
              padding-top: 1ex;
              margin-top: 1ex;
              width: 100%;
              border-top: solid 1px lightgrey;
            }
            .options {
              .option {
                margin-bottom: 6px;
              }
            }
          } // .SitesSelector, .SubjectsSelector, .SubjectLogsSelector 
        } // .ant-spin-container
        
      } // .ClinicalListingDialog
    } // .ant-modal-body
  } // .ant-modal-content
}
