.field-data-source-icon {
    width: 14px;
    height: 14px;
    margin-left: 6px;
    margin-top: 8px;
    margin-right: 10px;
    margin-bottom: 7px;
}

.data-source-indicator {
    position: relative;
    right: 25px;
    font-size: 13px;
    display: flex;
    align-items: center;
    direction: ltr !important;
    justify-content: right;
}